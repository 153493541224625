.bg-form-list {
    border: 2px solid #000000;
    border-radius: 5px;
}

.bg-form-list:nth-child(odd) {
    border-color: #E4F4FD;
}

.bg-form-list:nth-child(even) {
    border-color: #F5D098;
}